// src/pages/blog/RegraDeTresParaCriancas.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './RuleOfThreeForChildren.module.css';
import { Helmet } from 'react-helmet-async';

const RuleOfThreeForChildren: React.FC = () => {
    return (
        <div className={styles.articleContainer}>
            <Helmet>
                <title>Regra de Três para Crianças | 2Solve</title>
                <meta name="description" content="Aprenda a regra de três de forma simples e divertida! Veja exemplos e pratique com a nossa calculadora de regra de três online. Ideal para crianças e iniciantes." />
                <meta
                    name="keywords"
                    content="regra de três, como calcular regra de três, regra de três simples, regra de três para crianças, aprender regra de três, exemplo de regra de três, matemática fácil, matemática para crianças, matemática divertida, calcular regra de três online, calculadora de regra de três"
                />
                <meta property="og:title" content="Regra de Três para Crianças | 2Solve" />
                <meta property="og:description" content="Aprenda a regra de três de forma simples e divertida com exemplos práticos e uma calculadora online. Perfeito para crianças e iniciantes!" />
                <meta property="og:url" content="https://2solve.com.br/blog/regra-de-tres-para-criancas" />
                <meta property="og:type" content="article" />
            </Helmet>

            <h1>Regra de Três para Crianças: Aprenda Brincando!</h1>
            <p>Oi! Vamos aprender um jeito super fácil de fazer contas usando a regra de três? Parece complicado, mas é bem simples! 😄</p>

            <h2>O que é a Regra de Três?</h2>
            <p>A regra de três é um jeito legal de descobrir um número que está faltando, sabendo três outros números que se relacionam. Vamos pensar assim:</p>

            <ul>
                <li><strong>Imagine que:</strong> 1 saco de balas tem 10 balas.</li>
                <li>Então, quantas balas terão 5 sacos?</li>
            </ul>

            <p>Com a regra de três, você pode descobrir isso rapidinho!</p>

            <h2>Como Usar a Regra de Três?</h2>
            <p>Veja como fazer:</p>
            <ol>
                <li>Coloque os números que você conhece: 1 saco = 10 balas.</li>
                <li>Agora pense: 5 sacos = quantas balas?</li>
                <li>Multiplique em cruz: 1 saco x X balas = 5 sacos x 10 balas.</li>
                <li>Então, X = 5 x 10 = 50 balas!</li>
            </ol>

            <p>Viu só? Não foi tão difícil!</p>

            <h2>Experimente Agora!</h2>
            <p>Agora que você já sabe como funciona, que tal usar nossa <Link to="/calculadora-de-regra-de-3">Calculadora de Regra de Três</Link> e fazer suas próprias contas? 😊</p>

            <div className={styles.backToBlog}>
                <Link to="/blog" className={styles.backLink}>Voltar ao Blog</Link>
            </div>
        </div>
    );
};

export default RuleOfThreeForChildren;
