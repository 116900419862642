import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './TipCalculator.module.css';
import { Helmet } from 'react-helmet-async';

const TipCalculator: React.FC = () => {
    const [searchParams] = useSearchParams(); // Hook to read URL parameters
    const [billAmount, setBillAmount] = useState<string>('');
    const [tipPercentage, setTipPercentage] = useState<string>('10'); // Default tip percentage
    const [numberOfPeople, setNumberOfPeople] = useState<string>('1'); // Default number of people
    const [copied, setCopied] = useState<boolean>(false); // Copy state
    const [isError, setIsError] = useState<boolean>(false); // Error state
    const [results, setResults] = useState({
        tipAmount: 0,
        totalWithTip: 0,
        tipPerPerson: 0,
        totalPerPerson: 0,
    });

    // Function to calculate tip
    const calculateTip = useCallback(() => {
        const bill = parseFloat(billAmount);
        const tipPercent = parseFloat(tipPercentage);
        const people = parseInt(numberOfPeople, 10);

        if (isNaN(bill) || isNaN(tipPercent) || isNaN(people) || bill <= 0 || people <= 0) {
            setIsError(true);
            setResults({
                tipAmount: 0,
                totalWithTip: 0,
                tipPerPerson: 0,
                totalPerPerson: 0,
            });
            return;
        }

        setIsError(false);
        const tipAmount = (bill * tipPercent) / 100;
        const totalWithTip = bill + tipAmount;
        const tipPerPerson = tipAmount / people;
        const totalPerPerson = totalWithTip / people;

        setResults({
            tipAmount,
            totalWithTip,
            tipPerPerson,
            totalPerPerson,
        });
    }, [billAmount, tipPercentage, numberOfPeople]);

    // Set initial values from URL parameters
    useEffect(() => {
        const billParam = searchParams.get('billAmount');
        const tipParam = searchParams.get('tipPercentage');
        const peopleParam = searchParams.get('numberOfPeople');

        if (billParam) setBillAmount(billParam);
        if (tipParam) setTipPercentage(tipParam);
        if (peopleParam) setNumberOfPeople(peopleParam);
    }, [searchParams]);

    // Recalculate after URL parameters are set
    useEffect(() => {
        if (billAmount || tipPercentage || numberOfPeople) {
            calculateTip(); // Ensure calculation after setting states
        }
    }, [billAmount, tipPercentage, numberOfPeople, calculateTip]);

    const handleCopyResult = () => {
        const resultText = `Valor da conta: R$${billAmount}
Gorjeta (${tipPercentage}%): R$${results.tipAmount.toFixed(2)}
Total com Gorjeta: R$${results.totalWithTip.toFixed(2)}
${numberOfPeople !== '1' ? `Gorjeta por Pessoa: R$${results.tipPerPerson.toFixed(2)}
Total por Pessoa: R$${results.totalPerPerson.toFixed(2)}` : ''}`;

        navigator.clipboard.writeText(resultText);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleClearFields = () => {
        setBillAmount('');
        setTipPercentage('10');
        setNumberOfPeople('1');
        setIsError(true);
        setResults({
            tipAmount: 0,
            totalWithTip: 0,
            tipPerPerson: 0,
            totalPerPerson: 0,
        });
    };

    const handleShareLink = () => {
        const shareURL = `${window.location.origin}/calculadora-de-gorjeta?billAmount=${billAmount}&tipPercentage=${tipPercentage}&numberOfPeople=${numberOfPeople}`;
        navigator.clipboard.writeText(shareURL);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className={styles.tipCalculatorContainer}>
            <Helmet>
                <title>Calculadora de Gorjeta Online - Divida Contas Facilmente | 2Solve</title>
                <meta
                    name="description"
                    content="Use nossa calculadora de gorjeta para calcular facilmente o valor da gorjeta e dividir a conta entre amigos. Ferramenta ideal para restaurantes e encontros em grupo."
                />
                <meta
                    name="keywords"
                    content="calculadora de gorjeta, calcular gorjeta, dividir conta, divisão de conta, restaurante, como calcular gorjeta, dividir despesas, calculadora de restaurantes, calculadora online, calculo de gorjeta por pessoa"
                />
                <meta property="og:title" content="Calculadora de Gorjeta Online - Divida Contas Facilmente | 2Solve" />
                <meta property="og:description" content="Calcule o valor da gorjeta e divida a conta com nossa ferramenta simples e eficiente. Ideal para jantares e eventos com amigos!" />
                <meta property="og:url" content="https://2solve.com.br/calculadora-de-gorjeta" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://2solve.com.br/calculadora-de-gorjeta" />
            </Helmet>

            <h2>Calculadora de Gorjeta</h2>
            <p>Calcule o valor da gorjeta, o total da conta com gorjeta e divida a conta entre as pessoas.</p>

            <div className={styles.inputSection}>
                <div className={styles.inputGroup}>
                    <label>Valor da Conta:</label>
                    <input
                        type="number"
                        value={billAmount}
                        onChange={(e) => setBillAmount(e.target.value)}
                        placeholder="Digite o valor da conta"
                        className={styles.input}
                    />
                </div>

                <div className={styles.inputGroup}>
                    <label>Porcentagem da Gorjeta (%):</label>
                    <input
                        type="number"
                        value={tipPercentage}
                        onChange={(e) => setTipPercentage(e.target.value)}
                        placeholder="Digite a porcentagem da gorjeta"
                        className={styles.input}
                    />
                </div>

                <div className={styles.inputGroup}>
                    <label>Número de Pessoas:</label>
                    <input
                        type="number"
                        value={numberOfPeople}
                        onChange={(e) => setNumberOfPeople(e.target.value)}
                        placeholder="Digite o número de pessoas"
                        className={styles.input}
                    />
                </div>
            </div>

            <div className={styles.resultSection}>
                <h3>Resultados</h3>
                <p>Total sem Gorjeta: <strong>R${billAmount || '0.00'}</strong></p>
                <p>Valor da Gorjeta: <strong>R${results.tipAmount.toFixed(2)}</strong></p>
                <p>Total com Gorjeta: <strong>R${results.totalWithTip.toFixed(2)}</strong></p>
                {numberOfPeople !== '1' && (
                    <>
                        <p>Gorjeta por Pessoa: <strong>R${results.tipPerPerson.toFixed(2)}</strong></p>
                        <p>Total por Pessoa: <strong>R${results.totalPerPerson.toFixed(2)}</strong></p>
                    </>
                )}
            </div>

            <div className={styles.buttonSection}>
                {!isError && (
                    <>
                        <button className={styles.copyButton} onClick={handleCopyResult}>
                            Copiar Resultado
                        </button>
                        <button className={styles.shareButton} onClick={handleShareLink}>
                            Compartilhar Link
                        </button>
                    </>
                )}
                <button className={styles.clearButton} onClick={handleClearFields}>
                    Limpar Campos
                </button>
            </div>
            {copied && <span className={styles.copiedMessage}>Copiado!</span>}
        </div>
    );
};

export default TipCalculator;
