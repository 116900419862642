import React from 'react';
import styles from './About.module.css';

const About: React.FC = () => {
  return (
    <div className={styles.aboutPageContainer}>
      <h1>Sobre o 2Solve</h1>
      <p>Bem-vindo ao 2Solve! O objetivo é fornecer uma plataforma online de ferramentas rápidas e fáceis de usar para todos os seus cálculos diários e necessidades de resolução de problemas. De cálculos simples a mais complexos, o 2Solve está aqui para ajudar você a economizar tempo e obter resultados de forma prática.</p>

      <h2>Quem Somos</h2>
      <p>O 2Solve foi criado por um desenvolvedor que acredita na simplicidade e na eficiência como pilares para resolver os desafios do dia a dia. A missão é criar soluções que simplifiquem a vida das pessoas, permitindo que qualquer um tenha acesso a ferramentas poderosas e fáceis de usar, seja no computador ou no celular.</p>

      <h2>Nossa Filosofia</h2>
      <p>Acreditamos que ferramentas de cálculo online devem ser práticas, intuitivas e disponíveis para qualquer um que precise. Por isso, trabalhamos continuamente para aprimorar o 2Solve, adicionando novos recursos e garantindo uma experiência de usuário amigável em qualquer dispositivo. Nossa plataforma é desenhada para ser o seu "canivete suíço" digital de soluções rápidas!</p>

      <h2>Entre em Contato</h2>
      <p>Adoramos receber feedback dos nossos usuários! Se você tiver alguma dúvida, sugestão ou feedback, não hesite em entrar em contato conosco.</p>

      <p>Obrigado por escolher o 2Solve como sua plataforma de ferramentas rápidas para resolver problemas. Espero que você tenha uma excelente experiência!</p>
    </div>
  );
};

export default About;
