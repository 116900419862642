import React from 'react';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className={styles.privacyPolicyContainer}>
            <h2>Política de Privacidade</h2>
            <p>Bem-vindo ao 2solve! Esta política de privacidade descreve como coletamos, usamos, compartilhamos e protegemos suas informações ao utilizar as ferramentas de cálculo do nosso site. Ao acessar ou usar o 2solve, você concorda com os termos desta política de privacidade.</p>
            
            <h3>Coleta de Informações</h3>
            <ul>
                <li>Dados de entrada: Informações que você insere nas nossas ferramentas de cálculo, incluindo, mas não limitado a, números, cálculos e resultados.</li>
                <li>Dados de uso: Informações sobre como você usa as ferramentas do 2solve, como frequência de uso, funcionalidades acessadas, e possíveis erros encontrados.</li>
                <li>Dados de dispositivo: Informações sobre o dispositivo que você usa para acessar o 2solve, como tipo de dispositivo, sistema operacional, configurações de idioma e endereço IP.</li>
                <li>Dados de localização: Sua localização aproximada, determinada por seu endereço IP ou outras tecnologias de localização, para otimizar a experiência de uso e exibir conteúdo relevante.</li>
            </ul>

            <h3>Uso de Informações</h3>
            <p>Podemos usar as informações coletadas para:</p>
            <ul>
                <li>Fornecer, manter e melhorar as ferramentas de cálculo do 2solve e outros serviços relacionados.</li>
                <li>Personalizar sua experiência, incluindo a exibição de conteúdos e funcionalidades relevantes.</li>
                <li>Analisar o uso das ferramentas do 2solve e desenvolver novos recursos e funcionalidades.</li>
                <li>Prevenir, detectar e responder a atividades fraudulentas ou ilegais que possam ocorrer no site.</li>
                <li>Cumprir requisitos legais e regulatórios aplicáveis.</li>
            </ul>

            <h3>Compartilhamento de Informações</h3>
            <p>Podemos compartilhar suas informações com:</p>
            <ul>
                <li>Parceiros de publicidade: Para exibir anúncios personalizados, que sejam relevantes para você.</li>
                <li>Fornecedores de serviços de análise e telemetria: Para entender o uso do 2solve e melhorar nossos serviços.</li>
                <li>Autoridades legais: Quando exigido por lei ou para proteger nossos direitos ou os direitos de terceiros.</li>
            </ul>

            <h3>Segurança</h3>
            <p>Empregamos medidas de segurança adequadas para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum sistema de segurança é completamente seguro, e não podemos garantir a segurança absoluta de suas informações.</p>

            <h3>Cookies e Tecnologias Semelhantes</h3>
            <p>O 2solve pode usar cookies e outras tecnologias semelhantes para melhorar a sua experiência no site, personalizar conteúdos e anúncios, e analisar o tráfego. Você pode configurar seu navegador para rejeitar cookies ou alertá-lo quando cookies estiverem sendo enviados. No entanto, algumas partes do nosso site podem não funcionar corretamente sem cookies.</p>

            <h3>Alterações à Política de Privacidade</h3>
            <p>Podemos atualizar esta política de privacidade periodicamente. Quaisquer alterações entrarão em vigor imediatamente após a publicação da política de privacidade atualizada no nosso site. Recomendamos que você reveja esta política de tempos em tempos para se manter informado sobre como protegemos suas informações.</p>
        </div>
    );
};

export default PrivacyPolicy;
