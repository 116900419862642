import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './RuleOfThree.module.css';
import { Helmet } from 'react-helmet-async';

const RuleOfThree: React.FC = () => {
    const [searchParams] = useSearchParams();  // Hook para gerenciar parâmetros de URL
    const [value1, setValue1] = useState<string>(searchParams.get('value1') || '');  // Inicializa a partir da URL
    const [value2, setValue2] = useState<string>(searchParams.get('value2') || '');
    const [value3, setValue3] = useState<string>(searchParams.get('value3') || '');
    const [result, setResult] = useState<number | string>('');

    const [label1, setLabel1] = useState<string>(searchParams.get('label1') || '');
    const [label2, setLabel2] = useState<string>(searchParams.get('label2') || '');
    const [label3, setLabel3] = useState<string>(searchParams.get('label3') || '');
    const [labelResult, setLabelResult] = useState<string>(searchParams.get('labelResult') || '');

    const [isResultUpdated, setIsResultUpdated] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const [isValue1Valid, setIsValue1Valid] = useState<boolean>(true);
    const [isValue2Valid, setIsValue2Valid] = useState<boolean>(true);
    const [isValue3Valid, setIsValue3Valid] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    const isNumeric = (value: string) => {
        return !isNaN(parseFloat(value)) && isFinite(Number(value));
    };

    useEffect(() => {
        const value1Valid = isNumeric(value1) || value1 === '';
        const value2Valid = isNumeric(value2) || value2 === '';
        const value3Valid = isNumeric(value3) || value3 === '';

        setIsValue1Valid(value1Valid);
        setIsValue2Valid(value2Valid);
        setIsValue3Valid(value3Valid);

        if (value1 && value2 && value3) {
            if (value1Valid && value2Valid && value3Valid) {
                const calculated = (parseFloat(value3) * parseFloat(value2)) / parseFloat(value1);
                setResult(parseFloat(calculated.toFixed(4)));
                setIsResultUpdated(true);
                setIsError(false);
                setTimeout(() => setIsResultUpdated(false), 500);
            } else {
                setResult('Erro');
                setIsError(true);
            }
        } else {
            setResult('');
            setIsError(false);
        }
    }, [value1, value2, value3]);

    useEffect(() => {
        if (label1 && label2) {
            setLabel3(label1);
            setLabelResult(label2);
        }
    }, [label1, label2]);

    useEffect(() => {
        if (label3 && labelResult) {
            setLabel1(label3);
            setLabel2(labelResult);
        }
    }, [label3, labelResult]);

    const handleSwapValues = () => {
        setValue1(value2);
        setValue2(value1);
    };

    const handleCopyResult = () => {
        if (typeof result === 'number') {
            navigator.clipboard.writeText(result.toString());
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        }
    };

    const handleShareLink = () => {
        const params = new URLSearchParams();
        if (value1) params.set('value1', value1);
        if (value2) params.set('value2', value2);
        if (value3) params.set('value3', value3);
        if (label1) params.set('label1', label1);
        if (label2) params.set('label2', label2);
        if (label3) params.set('label3', label3);
        if (labelResult) params.set('labelResult', labelResult);

        const baseUrl = `${window.location.origin}${window.location.pathname}`;
        const shareableLink = `${baseUrl}?${params.toString()}`;

        navigator.clipboard.writeText(shareableLink);  // Copia o link para a área de transferência
        alert('Link copiado para a área de transferência!');  // Feedback ao usuário
    };

    const handleClearFields = () => {
        setValue1('');
        setValue2('');
        setValue3('');
        setLabel1('');
        setLabel2('');
        setLabel3('');
        setLabelResult('');
        setResult('');
        setIsError(false);
    };

    return (
        <div className={styles.ruleOfThreeContainer}>
            <Helmet>
                <title>Calculadora de Regra de Três Simples e Inversa | 2Solve</title>
                <meta
                    name="description"
                    content="Use a calculadora de Regra de Três do 2Solve para resolver problemas matemáticos de proporções simples e inversas. Aprenda como calcular proporções facilmente com exemplos práticos e obtenha resultados rápidos."
                />
                <meta
                    name="keywords"
                    content="regra de três, calculadora de regra de três, proporções, cálculo de proporção, matemática básica, resolver proporções, proporção direta, proporção inversa, cálculo matemático, ferramenta online, calculadora online, educação matemática, aprender matemática"
                />
                <meta property="og:title" content="Calculadora de Regra de Três Simples e Inversa | 2Solve" />
                <meta property="og:description" content="Resolva cálculos de Regra de Três simples e inversa com nossa ferramenta fácil de usar. Ideal para estudantes e profissionais que precisam de resultados rápidos e precisos." />
                <meta property="og:url" content="https://2solve.com.br/calculadora-de-regra-de-3" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://2solve.com.br/calculadora-de-regra-de-3" />
            </Helmet>

            <h2>Regra de 3</h2>
            <p>A calculadora de regra de três simples permite descobrir um número em proporção a outros dois que são conhecidos.</p>

            <div className={styles.calculationSection}>
                {/* Primeira linha de valores */}
                <div className={styles.row}>
                    <div className={styles.inputGroup}>
                        <input
                            type="text"
                            value={value1}
                            onChange={(e) => setValue1(e.target.value)}
                            placeholder="A"
                            className={`${styles.input} ${!isValue1Valid ? styles.inputError : ''}`}
                        />
                        <input
                            type="text"
                            value={label1}
                            onChange={(e) => setLabel1(e.target.value)}
                            placeholder="Ex: Bananas"
                            className={styles.textInput}
                        />
                    </div>

                    {/* Botão para inverter os valores e texto "Está para" */}
                    <div className={styles.swapContainer}>
                        <button className={styles.swapButton} onClick={handleSwapValues}>
                            ⇄
                        </button>
                        <span className={styles.label}>Está para</span>
                    </div>

                    <div className={styles.inputGroup}>
                        <input
                            type="text"
                            value={value2}
                            onChange={(e) => setValue2(e.target.value)}
                            placeholder="B"
                            className={`${styles.input} ${!isValue2Valid ? styles.inputError : ''}`}
                        />
                        <input
                            type="text"
                            value={label2}
                            onChange={(e) => setLabel2(e.target.value)}
                            placeholder="Ex: Cachos"
                            className={styles.textInput}
                        />
                    </div>
                </div>

                {/* Texto "ASSIM COMO" */}
                <div className={styles.centerText}>
                    <span>ASSIM COMO</span>
                </div>

                {/* Segunda linha de valores */}
                <div className={styles.row}>
                    <div className={styles.inputGroup}>
                        <input
                            type="text"
                            value={value3}
                            onChange={(e) => setValue3(e.target.value)}
                            placeholder="C"
                            className={`${styles.input} ${!isValue3Valid ? styles.inputError : ''}`}
                        />
                        <input
                            type="text"
                            value={label3}
                            onChange={(e) => setLabel3(e.target.value)}
                            placeholder="Ex: Bananas"
                            className={styles.textInput}
                        />
                    </div>
                    <span className={styles.label}>Está para</span>
                    <div className={styles.inputGroup}>
                        <input
                            data-testid="rule-of-three-result"
                            type="text"
                            value={result !== '' ? result.toString() : 'X'}
                            readOnly
                            className={`${styles.input} ${isError ? styles.resultError : styles.result} ${isResultUpdated ? styles.resultUpdated : ''}`}
                        />
                        <input
                            type="text"
                            value={labelResult}
                            onChange={(e) => setLabelResult(e.target.value)}
                            placeholder="Ex: Cachos"
                            className={styles.textInput}
                        />
                    </div>
                </div>

                {/* Botões para copiar o resultado, compartilhar link e limpar campos */}
                <div className={styles.buttonSection}>
                    {!isError && result !== '' && (
                        <>
                            <button className={styles.copyButton} onClick={handleCopyResult}>
                                Copiar Resultado
                            </button>
                            <button className={styles.shareButton} onClick={handleShareLink}>
                                Compartilhar Link
                            </button>
                        </>
                    )}
                    <button className={styles.clearButton} onClick={handleClearFields}>
                        Limpar Campos
                    </button>
                </div>

                {/* Mensagem de confirmação de cópia */}
                {isCopied && <span className={styles.copiedMessage}>Copiado!</span>}
            </div>

            <div className={styles.explanationSection}>
                <h3>O que é a regra de 3 simples?</h3>
                A regra de 3 simples é uma forma de encontrar um número que falta quando sabemos três outros números que estão relacionados.
                É como descobrir quantas balas você ganha se seu amigo ganha 10 balas e você tem a metade dele.
                <h3>Como fazer a regra de 3 simples?</h3>
                Coloque os números que você conhece em uma linha.
                <ol>
                    <li>Coloque o número que você quer descobrir na outra linha.</li>
                    <li>Multiplique os números em cruz (um de cima com o de baixo).</li>
                    <li>Depois, divida o resultado pelo número que sobrou. Isso vai dar a resposta!</li>
                </ol>
            </div>
        </div>
    );
};

export default RuleOfThree;
