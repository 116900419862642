import React from 'react';
import styles from './TermsOfUse.module.css';

const TermsOfUse: React.FC = () => {
  return (
    <div className={styles.termsContainer}>
      <h2>Termos de Uso</h2>
      <p>
        Bem-vindo ao 2solve! Ao acessar e utilizar este site, você concorda com os seguintes Termos de Uso.
        Se você não concorda com algum destes termos, por favor, não utilize este site.
      </p>

      <h3>1. Aceitação dos Termos</h3>
      <p>
        Ao usar o 2solve, você concorda em cumprir e estar vinculado a estes Termos de Uso. Reservamo-nos o direito
        de modificar estes termos a qualquer momento, e tais alterações entrarão em vigor imediatamente após a publicação.
        É sua responsabilidade revisar regularmente os Termos de Uso para se manter atualizado sobre quaisquer mudanças.
      </p>

      <h3>2. Uso do Site</h3>
      <p>
        O 2solve fornece ferramentas e funcionalidades para cálculos matemáticos rápidos e eficientes, bem como outras funcionalidades associadas.
        O uso deste site é por sua conta e risco. Não garantimos a exatidão dos cálculos ou a disponibilidade ininterrupta dos serviços oferecidos.
      </p>

      <h3>3. Conteúdo do Usuário</h3>
      <p>
        Ao utilizar as ferramentas do 2solve, você concorda em não carregar, postar ou compartilhar qualquer conteúdo que seja ilegal,
        ofensivo, difamatório ou que infrinja os direitos de terceiros. Reservamo-nos o direito de remover qualquer conteúdo que consideremos
        violar estes termos ou qualquer outra legislação aplicável.
      </p>

      <h3>4. Privacidade</h3>
      <p>
        Respeitamos sua privacidade e estamos comprometidos em protegê-la. Para entender como coletamos, usamos e protegemos suas informações pessoais,
        por favor, leia nossa <a href="/politica-de-privacidade">Política de Privacidade</a>.
      </p>

      <h3>5. Publicidade e Cookies</h3>
      <p>
        O 2solve pode utilizar serviços de terceiros, como o Google AdSense, para exibir anúncios. Esses serviços podem usar cookies para personalizar
        anúncios com base nas suas visitas anteriores ao 2solve ou a outros sites. Para saber mais sobre como o Google utiliza cookies e como você pode
        controlar suas preferências de privacidade, visite <a href="https://policies.google.com/technologies/partner-sites">Google Privacy & Terms</a>.
      </p>

      <h3>6. Isenção de Responsabilidade</h3>
      <p>
        Os serviços e ferramentas fornecidos pelo 2solve são oferecidos "como estão" e "conforme disponíveis". Não garantimos que os serviços estarão
        sempre disponíveis, livres de erros ou que quaisquer defeitos serão corrigidos. Não nos responsabilizamos por quaisquer danos diretos,
        indiretos, incidentais, consequenciais ou exemplares que possam surgir do uso do site ou de suas ferramentas.
      </p>

      <h3>7. Propriedade Intelectual</h3>
      <p>
        Todo o conteúdo e materiais disponíveis no 2solve, incluindo, mas não limitado a, textos, gráficos, logotipos e ícones, são de nossa propriedade exclusiva
        ou de nossos licenciadores e são protegidos por leis de direitos autorais, marcas registradas e outras leis de propriedade intelectual.
      </p>

      <h3>8. Links para Sites de Terceiros</h3>
      <p>
        O 2solve pode conter links para outros sites que não são controlados ou operados por nós. Não somos responsáveis pelo conteúdo, práticas de privacidade
        ou quaisquer outros aspectos de sites de terceiros. Recomendamos que você leia os termos de uso e políticas de privacidade de qualquer site de terceiros que visite.
      </p>

    </div>
  );
};

export default TermsOfUse;
