import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom for navigation
import styles from './SolverCard.module.css';

interface SolverCardProps {
    id: string;
    title: string;
    description: string;
    path: string;
}

const SolverCard: React.FC<SolverCardProps> = ({ title, description, path }) => {
    return (
        <Link to={path} className={styles.card}>
            {/* Convert the entire card into a link */}
            <h3>{title}</h3>
            <p>{description}</p>
        </Link>
    );
};

export default SolverCard;
