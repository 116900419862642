import React from 'react';
import { Link } from 'react-router-dom';
import { FaToolbox } from 'react-icons/fa';  // Importa o ícone da biblioteca react-icons
import styles from './Header.module.css';

const Header: React.FC = () => {
    return (
        <header className={styles.header}>
            <div className={styles.logo}>
                <Link to="/" className={styles.logoLink}>
                    2solve
                </Link>
            </div>

            {/* Link melhorado com ícone e estilo de botão */}
            <Link to="/" className={styles.toolsLink}>
                <FaToolbox className={styles.icon} /> {/* Ícone de uma caixa de ferramentas */}
                Ver Todas as Ferramentas
            </Link>

            {/* <div className={styles.tagline}>Ferramentas rápidas para resultados imediatos</div> */}
        </header>
    );
}

export default Header;
