// src/pages/blog/BlogPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Blog.module.css';
import { Helmet } from 'react-helmet-async';

const Blog: React.FC = () => {
    const articles = [
        {
            id: 'regra-de-tres-para-criancas',
            title: 'Regra de Três para Crianças: Aprenda Brincando!',
            description: 'Entenda como a regra de três funciona de forma simples e divertida!',
            link: '/blog/regra-de-tres-para-criancas'
        },
        // Adicione outros artigos aqui
    ];

    return (
        <div className={styles.blogContainer}>
            <Helmet>
                <title>Blog do 2Solve | Aprenda e Explore Conceitos com Nossas Ferramentas</title>
                <meta name="description" content="Explore o blog do 2Solve e aprenda conceitos matemáticos e dicas práticas com exemplos fáceis de entender. Descubra como usar nossas ferramentas para resolver problemas do dia a dia." />
                <meta
                    name="keywords"
                    content="blog de matemática, dicas de cálculo, ferramentas matemáticas, aprender matemática, resolver problemas matemáticos, dicas de estudo, regra de três, cálculo de porcentagem, matemática online, como calcular, ferramentas de cálculo, calculadora online"
                />
                <meta property="og:title" content="Blog do 2Solve | Aprenda e Explore Conceitos com Nossas Ferramentas" />
                <meta property="og:description" content="Explore o blog do 2Solve para aprender conceitos matemáticos e dicas práticas. Veja exemplos de uso das nossas ferramentas para resolver problemas do dia a dia." />
                <meta property="og:url" content="https://2solve.com.br/blog" />
                <meta property="og:type" content="website" />
            </Helmet>

            <h1>Nosso Blog</h1>
            <p>Aprenda conceitos de maneira fácil e divertida. Explore nossos artigos!</p>
            <div className={styles.articlesList}>
                {articles.map(article => (
                    <Link to={article.link} key={article.id} className={styles.articleCard}>
                        <h2>{article.title}</h2>
                        <p>{article.description}</p>
                        <span className={styles.readMoreLink}>Leia Mais</span>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Blog;
