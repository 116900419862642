import React from 'react';
import SolverCard from '../../components/solver-card/SolverCard';
import solversData from '../../solvers/solvers.json';
import styles from './HomePage.module.css';

// Definindo o tipo para os solvers
interface Solver {
    id: string;
    title: string;
    description: string;
    path: string;
    category: string;
}

const HomePage: React.FC = () => {
    // Agrupando solvers por categoria
    const categories = solversData.reduce((acc, solver: Solver) => {
        if (!acc[solver.category]) {
            acc[solver.category] = [];
        }
        acc[solver.category].push(solver);
        return acc;
    }, {} as Record<string, Solver[]>);

    return (
        <div className={styles.homePage}>
            
            {/* <SearchSection /> */}

            <div className={styles.solversSection}>
                {Object.entries(categories).map(([categoryTitle, solvers]) => (
                    <div key={categoryTitle} className={styles.category}>
                        <h2>{categoryTitle}</h2>
                        <div className={styles.cards}>
                            {solvers.map((solver) => (
                                <SolverCard key={solver.id} {...solver} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HomePage;
