import React from 'react';
import styles from './Footer.module.css';

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <nav>
                <a href="/politica-de-privacidade">Privacidade</a>
                <a href="/termos-de-uso">Termos de Uso</a>
                <a href="/sobre">Sobre</a>
            </nav>
        </footer>
    );
}

export default Footer;
